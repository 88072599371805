import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Inicio" />
    <h2>Hola mundo</h2>
    <p>
      Accede a las utilidades desde <Link to="/utils">este link</Link>,
      contactanos si tienes sugerencias desde{" "}
      <Link to="/contact"> este otro link</Link>.
    </p>
  </Layout>
)

export default IndexPage
